import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import JwtService from '@/core/services/jwt.service';
import { PRINOR_TOASTS } from '@/core/services/toast.service';
import { RESET_PROCESS } from '@/core/services/store/processing.module';

const HELPER = {
	catchFunc(error) {
		window.vueInstance.$store.dispatch(RESET_PROCESS);
		if (error.response.status === 401) {
			PRINOR_TOASTS.general.notAuthenticated(window.vueInstance);
		} else {
			if ('data' in error.response && error.response.data != null && 'error' in error.response.data) {
				PRINOR_TOASTS.general.error(window.vueInstance, error.response.data.error);
			} else {
				PRINOR_TOASTS.general.error(window.vueInstance);
			}
		}
	},
};

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = process.env.VUE_APP_URL_API;
		Vue.axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
	},

	/**
	 * Set the default HTTP request headers
	 */
	setHeader() {
		Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`;
	},

	query(resource, params) {
		return Vue.axios.get(resource, params).catch(error => HELPER.catchFunc(error));
	},

	/**
	 * Send the GET HTTP request
	 * @param resource
	 * @param slug
	 * @returns {*}
	 */
	get(resource, slug = '') {
		return Vue.axios.get(`${resource}/${slug}`).catch(error => HELPER.catchFunc(error));
	},

	/**
	 * Set the POST HTTP request
	 * @param resource
	 * @param params
	 * @returns {*}
	 */
	post(resource, params) {
		return Vue.axios.post(`${resource}`, params).catch(error => HELPER.catchFunc(error));
	},

	/**
	 * Send the UPDATE HTTP request
	 * @param resource
	 * @param slug
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	update(resource, slug, params) {
		return Vue.axios.put(`${resource}/${slug}`, params).catch(error => HELPER.catchFunc(error));
	},

	/**
	 * Send the PUT HTTP request
	 * @param resource
	 * @param params
	 * @returns {IDBRequest<IDBValidKey> | Promise<void>}
	 */
	put(resource, params) {
		return Vue.axios.put(`${resource}`, params).catch(error => HELPER.catchFunc(error));
	},

	/**
	 * Send the DELETE HTTP request
	 * @param resource
	 * @returns {*}
	 */
	delete(resource) {
		return Vue.axios.delete(resource).catch(error => HELPER.catchFunc(error));
	},

	checkSuccess: function(data) {
		if (typeof data !== 'undefined' && typeof data.data !== 'undefined' && data.data != null && 'success' in data.data && data.data.success === true) {
			return true;
		} else {
			return false;
		}
	},
};

export default ApiService;
