<template>
	<router-view></router-view>
</template>

<style lang="scss">
// 3rd party plugins css
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~socicon/css/socicon.css';
@import '~animate.css';
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import 'assets/plugins/flaticon/flaticon.css';
@import 'assets/plugins/flaticon2/flaticon.css';
@import 'assets/plugins/keenthemes-icons/font/ki.css';

// Main demo style scss
@import 'assets/sass/style.vue';

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.modal-border div.tabs > .tab-content {
	margin-top: 0 !important;
	padding: 0.75rem !important;
	padding-top: 1.5rem !important;
	border: 1px solid #E4E6EF;
	border-bottom-left-radius: 0.42rem;
	border-bottom-right-radius: 0.42rem;
	border-top: 0;
}
button.disabled,
a.disabled,
button.disabled:hover,
a.disabled:hover {
	cursor: not-allowed !important;
	pointer-events: none !important;
}
.swal2-container-max-height > #swal2-content {
	max-height: none !important;
}

.daily-statistic .prinor-table .b-table,
.daily-statistic .prinor-table .table-wrapper {
	height: 100% !important;
}

.prinor-editor-large fieldset.prinor-editor > div > div > .ql-editor{
	min-height: 30vh;
}

.prinor-table td.action-td,
.prinor-table th.action-td {
	text-align: right !important;
	white-space: nowrap !important;
	width: 1px !important;
}

.prinor-table .b-table a.pagination-link.is-current {
	background-color: #339AC3 !important;
	border-color: #339AC3 !important;
}
</style>

<script>
export default {
	name: 'MetronicVue',
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>