import Vue from 'vue';
import VueI18n from 'vue-i18n';

// Localisation language list
import * as enData from '@/core/config/i18n/en.json';
import * as deData from '@/core/config/i18n/de.json';

const en = enData.default;
const de = deData.default;

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, de };

// get current selected language
const lang = localStorage.getItem('language') || 'en';

// Create VueI18n instance with options
const i18n = new VueI18n({
	locale: lang, // set locale
	messages, // set locale messages
});

export default i18n;
