import Vue from 'vue';
import Router from 'vue-router';
import { PRINOR_PERMISSION } from '@/core/services/permissions.service';
import { SET_ACTION } from '@/core/services/store/actions.module';

Vue.use(Router);

let router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			redirect: '/dashboard',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/dashboard',
					name: 'dashboard',
					component: () => import('@/view/pages/Dashboard.vue'),
				},
				{
					path: '/account',
					name: 'account',
					component: () => import('@/view/pages/account/Profile.vue'),
				},
				{
					path: '/administration',
					component: () => import('@/view/pages/EmptyView.vue'),
					children: [
						{
							path: 'company',
							name: 'administration-company',
							component: () => import('@/view/pages/administration/Company.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'organisation',
							name: 'administration-organisation',
							component: () => import('@/view/pages/administration/Organisation.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'rights',
							name: 'administration-rights',
							component: () => import('@/view/pages/administration/Rights.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'user',
							name: 'administration-user',
							component: () => import('@/view/pages/administration/Users.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
					],
				},
				{
					path: '/super',
					component: () => import('@/view/pages/EmptyView.vue'),
					children: [
						{
							path: 'customer',
							name: 'super-customer',
							component: () => import('@/view/pages/super/Customer.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'support',
							name: 'super-support',
							component: () => import('@/view/pages/super/Support.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
					],
				},
				{
					path: '/customer-center',
					component: () => import('@/view/pages/EmptyView.vue'),
					children: [
						{
							path: 'dashboard',
							name: 'customer-center-dashboard',
							component: () => import('@/view/pages/customer-center/CustomerCenter'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'member-administration',
							name: 'member-administration',
							component: () => import('@/view/pages/customer-center/MemberCardAdministration'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'recommendation-center',
							name: 'recommendation-center',
							component: () => import('@/view/pages/customer-center/RecommendationCenter'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
					],
				},
				{
					path: '/support-center',
					component: () => import('@/view/pages/EmptyView.vue'),
					children: [
						{
							path: 'overview',
							name: 'support-center-home',
							component: () => import('@/view/pages/help-center/HelpCenter'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
					],
				},
				{
					path: '/survey',
					redirect: '/survey/all',
					component: () => import('@/view/pages/EmptyView.vue'),
					children: [
						{
							path: 'all',
							name: 'survey-all',
							component: () => import('@/view/pages/survey/AllSurveys.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'create',
							name: 'survey-create',
							component: () => import('@/view/pages/survey/SurveyEdit.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'edit/:id',
							name: 'survey-edit',
							component: () => import('@/view/pages/survey/SurveyEdit.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'design/:id',
							name: 'survey-design',
							component: () => import('@/view/pages/survey/SurveyDesign.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'designs',
							name: 'survey-designs',
							component: () => import('@/view/pages/survey/AllDesigns.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
						{
							path: 'mail/:id',
							name: 'survey-mail',
							component: () => import('@/view/pages/survey/EMail.vue'),
							beforeEnter: PRINOR_PERMISSION.guard,
						},
					],
				},
				{
					path: '/report/:id',
					name: 'report',
					component: () => import('@/view/pages/report/Report.vue'),
					beforeEnter: PRINOR_PERMISSION.guard,
				},
				{
					path: '/message-administration/email',
					name: 'message-mail',
					component: () => import('@/view/pages/message-administration/EMail/EMails'),
					beforeEnter: PRINOR_PERMISSION.guard,
				},
				{
					path: '/message-administration/sms',
					name: 'message-sms',
					component: () => import('@/view/pages/message-administration/Sms/Sms'),
					beforeEnter: PRINOR_PERMISSION.guard,
				},
				{
					path: '/message-administration/whatsapp',
					name: 'message-whatsapp',
					component: () => import('@/view/pages/message-administration/WhatsApp/WhatsApp'),
					beforeEnter: PRINOR_PERMISSION.guard,
				},
			],
		},
		// {
		// 	path: '/docs',
		// 	name: 'docs',
		// 	redirect: '/docs/api',
		// 	component: () => import('@/view/pages/EmptyView.vue'),
		// 	children: [
		// 		{
		// 			path: 'api',
		// 			name: 'docs-api',
		// 			component: () => import('@/view/pages/docs/Api'),
		// 		},
		// 	],
		// },
		{
			path: '/s/:uri/:customer?',
			component: () => import('@/view/pages/subscriber/SubscriberSurvey'),
			name: 'subscriber-survey',
		},
		{
			path: '/preview/:id',
			component: () => import('@/view/pages/subscriber/SubscriberSurvey'),
			name: 'survey-preview',
		},
		{
			path: '/custom-error',
			name: 'error',
			component: () => import('@/view/pages/error/Error.vue'),
			children: [
				{
					path: 'error-1',
					name: 'error-1',
					component: () => import('@/view/pages/error/Error-1.vue'),
				},
				{
					path: 'error-2',
					name: 'error-2',
					component: () => import('@/view/pages/error/Error-2.vue'),
				},
				{
					path: 'error-3',
					name: 'error-3',
					component: () => import('@/view/pages/error/Error-3.vue'),
				},
				{
					path: 'error-4',
					name: 'error-4',
					component: () => import('@/view/pages/error/Error-4.vue'),
				},
				{
					path: 'error-5',
					name: 'error-5',
					component: () => import('@/view/pages/error/Error-5.vue'),
				},
				{
					path: 'error-6',
					name: 'error-6',
					component: () => import('@/view/pages/error/Error-6.vue'),
				},
			],
		},
		{
			path: '/',
			component: () => import('@/view/pages/auth/Auth'),
			children: [
				{
					name: 'login',
					path: '/login',
					component: () => import('@/view/pages/auth/Login'),
				},
				{
					name: 'register',
					path: '/register',
					component: () => import('@/view/pages/auth/Register'),
				},
			],
		},
		{
			path: '*',
			redirect: '/404',
		},
		{
			// the 404 route, when none of the above matches
			path: '/404',
			name: '404',
			component: () => import('@/view/pages/error/Error-1.vue'),
		},
	],
});

router.beforeEach((to, from, next) => {
	if (typeof router.app.$store != 'undefined') {
		router.app.$store.dispatch(SET_ACTION, []);
	}

	next();
});

export default router;
