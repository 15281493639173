// mutation types
export const SET_PROCESS = 'setProcessIndicator';
export const RESET_PROCESS = 'resetProcessIndicators';

export default {
	state: {
		pageProcesses: {
			isUpdating: false,
			isLoading: false,
			isDeleting: false,
		},
	},
	getters: {
		pageProcesses(state) {
			return state.pageProcesses;
		},
	},
	actions: {
		[SET_PROCESS](state, payload) {
			state.commit(SET_PROCESS, payload);
		},
		[RESET_PROCESS](state) {
			state.commit(RESET_PROCESS);
		},
	},
	mutations: {
		[SET_PROCESS](state, payload) {
			state.pageProcesses[payload.name] = payload.value;
		},
		[RESET_PROCESS](state) {
			state.pageProcesses.isLoading = false;
			state.pageProcesses.isUpdating = false;
			state.pageProcesses.isDeleting = false;
		},
	},
};
