<template>
	<div>
		<h4>{{ heading }}</h4>
		<p>{{ message }} (<b>{{ code }}</b>)</p>
	</div>
</template>

<script>
export default {
	name: 'ToastError',
	props: {
		heading: String,
		message: String,
		code: String,
	},
};
</script>

<style scoped>
h4 {
	font-weight: 500;
	border-bottom: 1px solid #fff;
}
</style>
