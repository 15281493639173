import Vue from "vue";
import { BootstrapVue, BVConfigPlugin, IconsPlugin } from 'bootstrap-vue';

Vue.use(BVConfigPlugin, {
	BModal: { centered: true },
});
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
