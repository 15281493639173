import store from '@/core/services/store/auth.module';

const HELPER = {
	checkNavigationPermission: function(to) {
		switch (to.name) {
			case 'administration-company':
				return store.state.permissions.includes('menu.admin.company');
			case 'administration-organisation':
				return store.state.permissions.includes('menu.admin.organization');
			case 'administration-user':
				return store.state.permissions.includes('menu.admin.right');
			case 'administration-rights':
				return store.state.permissions.includes('menu.admin.user');
			case 'survey-all':
				return store.state.permissions.includes('menu.survey.all');
			case 'survey-designs':
				return store.state.permissions.includes('menu.survey.theme');
			default:
				return true;
		}
	},
	checkPermission: function(name) {
		return store.state.permissions.includes(name);
	},
};

export const PRINOR_PERMISSION = {
	guard: function(to, from, next) {
		if (store.state.isAuthenticated === true) {
			if (HELPER.checkNavigationPermission(to) === true) {
				next();
			} else {
				next({
					path: '/login',
					query: {
						redirect: to.fullPath
					}
				});
			}
		} else {
			next({
				path: '/login',
				query: {
					redirect: to.fullPath
				}
			});
		}
	},
	menu: function(name) {
		return HELPER.checkPermission(name) === true;
	},
	action: function(name) {
		return HELPER.checkPermission(name) === true;
	},
};
