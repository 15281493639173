import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
// import MockService from "@/core/mock/mock.service";
import { VERIFY_AUTH } from '@/core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';
Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect)

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
Vue.component('vue-slider', VueSlider);

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
// import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/treeselect';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
import '@/core/plugins/formvalidation';
import '@/assets/css/fa-pulsate.css';

// API service init
ApiService.init();

// // Remove this to disable mock API
// MockService.init();

router.beforeEach((to, from, next) => {
	// Ensure we checked auth before each page load.
	Promise.all([store.dispatch(VERIFY_AUTH, to)]).then(next);

	// reset config to initial state
	store.dispatch(RESET_LAYOUT_CONFIG);

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
Vue.use(VueLoaders);

import { ConfigProgrammatic, Table } from 'buefy';
Vue.use(Table);
ConfigProgrammatic.setOptions({
	defaultIconPack: 'fas',
});

import vmodal from 'vue-js-modal';
Vue.use(vmodal);

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
Vue.use(Toast);

import frag from 'vue-frag';
Vue.directive('frag', frag);

import ToggleButton from 'vue-js-toggle-button';
Vue.use(ToggleButton);

import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css';
Vue.component('vue-timepicker', VueTimepicker);

import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
	componentPrefix: 'vc',
});

import Papa from 'papaparse';
window.Papa = Papa;

import draggable from 'vuedraggable';
Vue.component('draggable', draggable);

import ContentEditable from 'vue-input-contenteditable';
Vue.component('input-contenteditable', ContentEditable);

import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
Vue.component('popper', Popper);

import { Chrome } from 'vue-color';
Vue.component('chrome-picker', Chrome);

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
Vue.component('vue-select', vSelect);

import VirtualList from 'vue-virtual-scroll-list';
Vue.component('virtual-list', VirtualList);

import FingerprintJS from '@fingerprintjs/fingerprintjs';
(async () => {
	// We recommend to call `load` at application startup.
	const fp = await FingerprintJS.load();

	// The FingerprintJS agent is ready.
	// Get a visitor identifier when you'd like to.
	const result = await fp.get();

	// This is the visitor identifier:
	window.visitorId = result.visitorId;
})();

import Quill from "quill/dist/quill";
var Embed = Quill.import('blots/embed');

class TemplateMarker extends Embed {
	static create(value) {
		let node = super.create(value);
		node.setAttribute('class', 'badge badge-' + value.color);
		node.setAttribute('data-marker', value.marker);
		node.setAttribute('data-title', value.title);
		node.setAttribute('data-color', value.color);
		node.innerHTML = value.title;
		return node;
	}

	static value(node) {
		return {
			marker: node.getAttribute('data-marker'),
			title: node.getAttribute('data-title'),
			color: node.getAttribute('data-color'),
		};
	}
}

TemplateMarker.blotName = 'TemplateMarker';
TemplateMarker.tagName = 'span';

Quill.register({
	'formats/TemplateMarker': TemplateMarker
});

window.Quill = Quill;
import 'quill/dist/quill.snow.css';
window.QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;

// import Swal from "sweetalert2";
// let toast = Swal.mixin({
// 	heightAuto: false
// });

import VueFinalModal from 'vue-final-modal';
Vue.use(VueFinalModal());

let vueInstance = new Vue({
	router,
	store,
	i18n,
	// vuetify,
	render: h => h(App),
}).$mount('#app');

window.vueInstance = vueInstance;

Date.prototype.yyyymmdd = function() {
	var mm = this.getMonth() + 1; // getMonth() is zero-based
	var dd = this.getDate();

	return [this.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd, this.getHours(), this.getMinutes(), this.getSeconds()].join('');
};
Date.prototype.addDays = function(days) {
	var date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
};