// action types
export const APPEND_ACTION = 'appendAction';

// mutation types
export const SET_ACTION = 'setAction';
export const SET_PROCESSING = 'setActionProcessing';
export const ADD_ACTION = 'addAction';
export const SET_DISABLED = 'setActionDisabled';

export default {
	state: {
		pageActions: [],
	},
	getters: {
		pageActions(state) {
			return state.pageActions;
		},
	},
	actions: {
		[SET_ACTION](state, payload) {
			state.commit(SET_ACTION, payload);
		},

		[ADD_ACTION](state, payload) {
			if (typeof payload === 'object') {
				payload.forEach(item => state.commit(APPEND_ACTION, item));
			} else {
				state.commit(APPEND_ACTION, payload);
			}
		},

		[SET_PROCESSING](state, payload) {
			state.commit(SET_PROCESSING, payload);
		},

		[SET_DISABLED](state, payload) {
			state.commit(SET_DISABLED, payload);
		},
	},
	mutations: {
		[APPEND_ACTION](state, payload) {
			state.pageActions = [...state.pageActions, payload];
		},
		[SET_ACTION](state, payload) {
			state.pageActions = payload;
		},
		[SET_PROCESSING](state, payload) {
			state.pageActions.forEach(action => {
				if (action.id === payload.id) {
					action.processing = payload.processing;
				}
			});
		},
		[SET_DISABLED](state, payload) {
			state.pageActions.forEach(action => {
				if (action.id === payload.id) {
					action.disabled = payload.disabled;
				}
			});
		},
	},
};
