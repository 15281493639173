import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_PASSWORD = 'updateUser';
export const ERROR = 'Error';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_PASSWORD = 'setPassword';
export const SET_ERROR = 'setError';
export const SET_USER = 'setUserObj';

const state = {
	errors: null,
	user: {},
	permissions: [],
	isAuthenticated: !!JwtService.getToken(),
	isOrganisationActive: false,
	surveyUrl: 'https://umfrage.digital/',
};

const getters = {
	currentUser(state) {
		return state.user;
	},
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
	isOrganisationActive(state) {
		return state.isOrganisationActive;
	},
	getErrors(state) {
		return state.errors;
	},
	getPermissions(state) {
		return state.permissions;
	},
	getSurveyUrl(state) {
		return state.surveyUrl;
	},
};

const actions = {
	[LOGIN](context, credentials) {
		return new Promise(resolve => {
			ApiService.post('auth', credentials)
				.then(data => {
					if (typeof data !== 'undefined') {
						context.commit(SET_AUTH, data.data);
						resolve(data.data);
					} else {
						resolve(data);
					}
				})
				.catch(error => {
					context.commit(SET_ERROR, error);
					resolve(error);
				});
		});
	},
	[LOGOUT](context) {
		context.commit(PURGE_AUTH);
	},
	[REGISTER](context, credentials) {
		return new Promise(resolve => {
			ApiService.post('login', credentials)
				.then(({ data }) => {
					context.commit(SET_AUTH, data);
					resolve(data);
				})
				.catch(({ response }) => {
					context.commit(SET_ERROR, response.data.errors);
				});
		});
	},
	[VERIFY_AUTH](context, to) {
		if (JwtService.getToken()) {
			ApiService.setHeader();
			ApiService.get('auth')
				.then(({ data }) => {
					if (data.success === true) {
						context.commit(SET_USER, data.data);
					} else {
						context.commit(PURGE_AUTH);
					}
				})
				.catch(() => {
					// context.commit(SET_ERROR, response.data.errors);
					context.commit(PURGE_AUTH);

					if (typeof window.vueInstance === 'undefined') {
						window.location.reload();
					}
					else {
						window.vueInstance._router.push({
							path: '/login',
							query: {
								redirect: to.fullPath
							}
						});
					}
				});
		} else {
			context.commit(PURGE_AUTH);
		}
	},
	[UPDATE_PASSWORD](context, payload) {
		const password = payload;

		return ApiService.put('password', password).then(({ data }) => {
			context.commit(SET_PASSWORD, data);
			return data;
		});
	},
	[ERROR](context, payload) {
		context.commit(SET_ERROR, payload);
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_AUTH](state, user) {
		state.isAuthenticated = true;
		state.user = user;
		state.permissions = user.permissions;
		state.errors = {};
		state.surveyUrl = user.survey_url;
		JwtService.saveToken(state.user.token);
	},
	[SET_PASSWORD](state, password) {
		state.user.password = password;
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		state.errors = {};
		JwtService.destroyToken();
	},
	[SET_USER](state, data) {
		state.user = data.user;
		state.permissions = data.permissions;
		state.isAuthenticated = true;
		state.surveyUrl = data.survey_url;
		state.isOrganisationActive = data.organization_active;
		state.errors = {};
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
