import ToastError from '@/view/component/misc/Toasts/ToastError';

const HELPER = {
	errorMessage: function(that, error) {
		let code = error.code.split('-');
		if (code.length === 2) {
			return that.$t('ERROR.' + code[0] + '.' + code[1]);
		} else {
			return '';
		}
	},
};

export const PRINOR_TOASTS = {
	save: {
		confirmation(that) {
			that.$toast.success(that.$t('GENERAL.Toasts.saveConfirmation'), {
				timeout: 2000,
			});
		},
		error(that, error = '') {
			if (error !== '') {
				let ErrorComponent = {
					component: ToastError,
					props: {
						message: HELPER.errorMessage(that, error),
						heading: that.$t('GENERAL.Toasts.saveError'),
						code: error.code,
					},
				};

				that.$toast.error(ErrorComponent, {
					timeout: false,
				});
			} else {
				that.$toast.error(that.$t('GENERAL.Toasts.saveError'), {
					timeout: false,
				});
			}
		},
	},
	delete: {
		error(that, error = '') {
			if (error !== '') {
				let ErrorComponent = {
					component: ToastError,
					props: {
						message: HELPER.errorMessage(that, error),
						heading: that.$t('GENERAL.Toasts.deleteError'),
						code: error.code,
					},
				};

				that.$toast.error(ErrorComponent, {
					timeout: false,
				});
			} else {
				that.$toast.error(that.$t('GENERAL.Toasts.deleteError'), {
					timeout: false,
				});
			}
		},
	},
	general: {
		confirmation(that, text) {
			that.$toast.success(text, {
				timeout: 2000,
			});
		},
		error(that, error = '') {
			if (error !== '') {
				let ErrorComponent = {
					component: ToastError,
					props: {
						message: HELPER.errorMessage(that, error),
						heading: that.$t('GENERAL.Toasts.generalError'),
						code: error.code,
					},
				};

				that.$toast.error(ErrorComponent, {
					timeout: false,
				});
			} else {
				that.$toast.error(that.$t('GENERAL.Toasts.generalError'), {
					timeout: false,
				});
			}
		},
		info(that, error = '') {
			if (error !== '') {
				let ErrorComponent = {
					component: ToastError,
					props: {
						message: HELPER.errorMessage(that, error),
						heading: that.$t('GENERAL.Toasts.generalError'),
						code: error.code,
					},
				};

				that.$toast.error(ErrorComponent, {
					timeout: false,
				});
			} else {
				that.$toast.error(that.$t('GENERAL.Toasts.generalError'), {
					timeout: false,
				});
			}
		},
		notAuthenticated(that) {
			that.$toast.error(that.$t('ERROR.G.notAuthenticated'), {
				timeout: false,
			});
		},
	},
};
